import React from 'react';
import Slider from '../components/slider.jsx';
import '../css/home.css';
import { Helmet } from 'react-helmet';
// eslint-disable-next-line
import { Link } from 'react-router-dom';
import ReactPlayer from 'react-player';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGuitar } from '@fortawesome/free-solid-svg-icons';

const Home = (props) => {
	return (
		<React.Fragment>
			<Helmet>
				<title> Music Academy | Home</title>
			</Helmet>
			<Slider />
			<Features />
			<Video />
			<Instruments />
			<div className="container px-4 py-5" id="hanging-icons">
				<h2 className="pb-2 border-bottom teachers">Our Teachers</h2>
				<div className="d-flex flex-wrap cards">
					<div className="card teach-cards">
						<img src="..." className="card-img-top" alt="..." />
						<div className="card-body">
							<h5 className="card-title">Card title</h5>
							<p className="card-text">
								Some quick example text to build on the card
								title and make up the bulk of the card's
								content.
							</p>
						</div>
					</div>
					<div className="card teach-cards">
						<img src="..." className="card-img-top" alt="..." />
						<div className="card-body">
							<h5 className="card-title">Card title</h5>
							<p className="card-text">
								Some quick example text to build on the card
								title and make up the bulk of the card's
								content.
							</p>
						</div>
					</div>
					<div className="card teach-cards">
						<img src="..." className="card-img-top" alt="..." />
						<div className="card-body">
							<h5 className="card-title">Card title</h5>
							<p className="card-text">
								Some quick example text to build on the card
								title and make up the bulk of the card's
								content.
							</p>
						</div>
					</div>
				</div>
			</div>
		</React.Fragment>
	);
};

const Features = () => {
	return (
		<div className="features my-4">
			<div className="container">
				<h2 className="pb-2 border-bottom my-4">Features</h2>
				<div className="row g-4 py-5 row-cols-1 row-cols-lg-3">
					<div className="col d-flex align-items-start">
						<div className="icon-square text-body-emphasis bg-body-secondary d-inline-flex align-items-center justify-content-center fs-4 flex-shrink-0 me-3">
							<svg className="bi" width="1em" height="1em">
								{/* <use xlink:href="#toggles2" /> */}
							</svg>
						</div>
						<div>
							<h3 className="fs-2">Featured title</h3>
							<p>
								Paragraph of text beneath the heading to explain
								the heading. We'll add onto it with another
								sentence and probably just keep going until we
								run out of words.
							</p>
							{/* <Link href="#" className="btn btn-primary">
								Primary button
							</Link> */}
						</div>
					</div>
					<div className="col d-flex align-items-start">
						<div className="icon-square text-body-emphasis bg-body-secondary d-inline-flex align-items-center justify-content-center fs-4 flex-shrink-0 me-3">
							<svg className="bi" width="1em" height="1em">
								{/* <use xlink:href="#cpu-fill" /> */}
							</svg>
						</div>
						<div>
							<h3 className="fs-2">Featured title</h3>
							<p>
								Paragraph of text beneath the heading to explain
								the heading. We'll add onto it with another
								sentence and probably just keep going until we
								run out of words.
							</p>
							{/* <Link href="#" className="btn btn-primary">
                Primary button
              </Link> */}
						</div>
					</div>
					<div className="col d-flex align-items-start">
						<div className="icon-square text-body-emphasis bg-body-secondary d-inline-flex align-items-center justify-content-center fs-4 flex-shrink-0 me-3">
							<svg className="bi" width="1em" height="1em">
								{/* <use xlink:href="#tools" /> */}
							</svg>
						</div>
						<div>
							<h3 className="fs-2">Featured title</h3>
							<p>
								Paragraph of text beneath the heading to explain
								the heading. We'll add onto it with another
								sentence and probably just keep going until we
								run out of words.
							</p>
							{/* <Link href="#" className="btn btn-primary">
                Primary button
              </Link> */}
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

const Instruments = () => {
	return (
		<React.Fragment>
			<div className="instruments">
				<div className="instruments-container">
					<div className="instrument">
						<FontAwesomeIcon icon={faGuitar} />

						<p>Guitar</p>
					</div>
				</div>
			</div>
		</React.Fragment>
	);
};

const Video = () => {
	return (
		<React.Fragment>
			<h2 className='text-center my-4'>Introduction</h2>
			<center>
			<ReactPlayer url="http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/BigBuckBunny.mp4" controls className="video"/>

			</center>
		</React.Fragment>
	);
};

export default Home;
