import React from "react";
import { Helmet } from "react-helmet";

const locateUs = (props) => {
  return (
    <React.Fragment>
      <Helmet>
        <title> Music Academy | Locate Us</title>
      </Helmet>
      Locate US
    </React.Fragment>
  );
};

export default locateUs;
