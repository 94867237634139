import React from "react";
import { Helmet } from "react-helmet";

const About = (props) => {
  return (
    <React.Fragment>
      <Helmet>
        <title> Music Academy | About</title>
      </Helmet>
      <div className="About">
        <div className="container col-xxl-8 px-4 py-5">
          <div className="row flex-lg-row-reverse align-items-center g-5 py-5">
            <div className="col-10 col-sm-8 col-lg-6">
              <img
                src="bootstrap-themes.png"
                className="d-block mx-lg-auto img-fluid"
                alt="Primary Hero Page"
                width="700"
                height="500"
              />
            </div>
            <div className="col-lg-6">
              <h1 className="display-5 fw-bold text-body-emphasis lh-1 mb-3">
                Bla Bla Music Academy
              </h1>
              <p className="lead">
                Quickly design and customize responsive mobile-first sites with
                Bootstrap, the world’s most popular front-end open source
                toolkit, featuring Sass variables and mixins, responsive grid
                system, extensive prebuilt components, and powerful JavaScript
                plugins.
              </p>
              <div className="d-grid gap-2 d-md-flex justify-content-md-start">
                <button
                  type="button"
                  className="btn btn-primary btn-lg px-4 me-md-2"
                >
                  Enroll
                </button>
                <button
                  type="button"
                  className="btn btn-outline-secondary btn-lg px-4"
                >
                  Default
                </button>
              </div>
            </div>
          </div>
        </div>

        <div className="container">
          <div className="card mb-3 cards-about border-0">
            <div className="row g-0">
              <div className="col-md-4">
                <img src="..." className="img-fluid rounded-start" alt="..." />
              </div>
              <div className="col-md-8">
                <div className="card-body">
                  <h5 className="card-title">Card title</h5>
                  <p className="card-text">
                    This is a wider card with supporting text below as a natural
                    lead-in to additional content. This content is a little bit
                    longer.
                  </p>
                  <p className="card-text">
                    {/* <small className="text-body-secondary">
                      Last updated 3 mins ago
                    </small> */}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="card mb-3 cards-about border-0">
            <div className="row g-0">
              <div className="col-md-8">
                <div className="card-body d-flex">
                  <div className="card-part">
                    <h5 className="card-title">Card title</h5>
                    <p className="card-text">
                      This is a wider card with supporting text below as a
                      natural lead-in to additional content. This content is a
                      little bit longer.
                    </p>
                  </div>
                  <div className="col-md-4">
                    <img
                      src="..."
                      className="img-fluid rounded-start"
                      alt="..."
                    />
                  </div>
                  <p className="card-text">
                    {/* <small className="text-body-secondary">
                      Last updated 3 mins ago
                    </small> */}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="card mb-3 cards-about border-0">
            <div className="row g-0">
              <div className="col-md-4">
                <img src="..." className="img-fluid rounded-start" alt="..." />
              </div>
              <div className="col-md-8">
                <div className="card-body">
                  <h5 className="card-title">Card title</h5>
                  <p className="card-text">
                    This is a wider card with supporting text below as a natural
                    lead-in to additional content. This content is a little bit
                    longer.
                  </p>
                  <p className="card-text">
                    {/* <small className="text-body-secondary">
                      Last updated 3 mins ago
                    </small> */}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default About;
