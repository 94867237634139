import './App.css';
// eslint-disable-next-line
import React, { useState } from 'react';
import Header from './app/components/header.jsx';
import Footer from './app/components/footer.jsx';
import Home from './app/pages/home.jsx';
import Contact from './app/pages/contact.jsx';
import About from './app/pages/about.jsx';
import Pricing from './app/pages/pricing.jsx';
import Locate from './app/pages/locateus.jsx';
// eslint-disable-next-line
import Map from './app/components/map/map';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

const App = (props) => {
	return (
		<React.Fragment data-bs-theme="dark">
			<Router>
				<Header title="Music Academy test" searchBar={false} dark={false} />
				<Routes>
					<Route path="/" exact element={<Home />} />
					<Route path="/about" exact element={<About />} />
					<Route path="/pricing" exact element={<Pricing />} />
					<Route path="/contact" exact element={<Contact />} />
					<Route path="/locate-us" exact element={<Locate />} />
				</Routes>
				<Footer
					copyright="Music Academy"
					year="2023"
					company="Music Academy"
				/>
			</Router>
		</React.Fragment>
	);
};

export default App;
