import React from "react";
import "../css/pricing.css";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

const Pricing = (props) => {
  return (
    <React.Fragment>
      <Helmet>
        <title> Music Academy | Pricing</title>
      </Helmet>
      <div className="price-head">
        <h1 className="text-center my-4">Our Courses</h1>
      </div>
      <div className="container">
        <div className="row row-cols-1 row-cols-md-3 text-center">
          <div className="col">
            <div className="card mb-4 rounded-3 shadow-sm">
              <div className="card-header py-3">
                <h4 className="my-0 fw-normal">Starter</h4>
              </div>
              <div className="card-body">
                <h1 className="card-title pricing-card-title">
                  &#x20B9;1000
                  <small className="text-body-secondary fw-light">/month</small>
                </h1>
                <ul className="list-unstyled mt-3 mb-4">
                  <li>15 Classes/month</li>
                  <li>2 GB of storage</li>
                  <li>No Instrument provided</li>
                  <li>Help center access</li>
                </ul>
                <button
                  type="button"
                  className="w-100 btn btn-lg btn-outline-primary"
                >
                  <Link to="/contact" className="contact-price">
                    Contact Us
                  </Link>
                </button>
              </div>
            </div>
          </div>
          <div className="col">
            <div className="card mb-4 rounded-3 shadow-sm">
              <div className="card-header py-3">
                <h4 className="my-0 fw-normal">Intermediate</h4>
              </div>
              <div className="card-body">
                <h1 className="card-title pricing-card-title">
                  &#x20B9;1500
                  <small className="text-body-secondary fw-light">/month</small>
                </h1>
                <ul className="list-unstyled mt-3 mb-4">
                  <li>20 Classes/month</li>
                  <li>10 GB of storage</li>
                  <li>No Instrument provided</li>
                  <li>Help center access</li>
                </ul>
                <button
                  type="button"
                  className="w-100 btn btn-lg btn-outline-primary"
                >
                  <Link to="/contact" className="contact-price">
                    Contact Us
                  </Link>
                </button>
              </div>
            </div>
          </div>
          <div className="col">
            <div className="card mb-4 rounded-3 shadow-sm border-primary">
              <div className="card-header py-3 text-bg-primary border-primary">
                <h4 className="my-0 fw-normal">Pro</h4>
              </div>
              <div className="card-body">
                <h1 className="card-title pricing-card-title">
                  &#x20B9;2000
                  <small className="text-body-secondary fw-light">/month</small>
                </h1>
                <ul className="list-unstyled mt-3 mb-4">
                  <li>Full Month &#40;Execpt Holidays&#41;</li>
                  <li>15 GB of storage</li>
                  <li>Instrument provided &#40;Only in Class&#41;</li>
                  <li>Help center access</li>
                </ul>
                <button type="button" className="w-100 btn btn-lg btn-primary">
                  <Link to="/contact" className="contact-price">
                    Contact Us
                  </Link>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Pricing;
