import React, {useState} from 'react';
// eslint-disable-next-line
// eslint-disable-next-line
// eslint-disable-next-line
import { NavLink, Link } from 'react-router-dom';
import { PropTypes } from 'prop-types';

const Header = (props) => {
	let [Clicked, setClicked] = useState(true);

	const themeChange = () => {
		setClicked(Clicked = !Clicked);
	};
	return (
		<React.Fragment>
			<nav
				className="navbar navbar-expand-lg bg-body-tertiary sticky-top"
				data-bs-theme={Clicked ? 'dark' : 'light'}>
				<div className="container-fluid">
					<Link className="navbar-brand" to="/">
						<span className="text-warning">{props.title}</span>
					</Link>
					<button
						className="navbar-toggler"
						type="button"
						data-bs-toggle="collapse"
						data-bs-target="#navbarSupportedContent"
						aria-controls="navbarSupportedContent"
						aria-expanded="false"
						aria-label="Toggle navigation">
						<span className="navbar-toggler-icon"></span>
					</button>
					<div
						className="collapse navbar-collapse"
						id="navbarSupportedContent">
						<ul className="navbar-nav me-auto mb-2 mb-lg-0">
							<li className="nav-item">
								<NavLink
									className={({ isActive }) =>
										isActive
											? 'nav-link btn btn-danger active'
											: 'nav-link inactive'
									}
									aria-current="page"
									to="/">
									Home
								</NavLink>
							</li>
							<li
								className={({ isActive }) =>
									isActive
										? 'nav-link active'
										: 'nav-link inactive'
								}>
								<Link className="nav-link" to="/pricing">
									Pricing
								</Link>
							</li>

							<li
								className={({ isActive }) =>
									isActive
										? 'nav-link active'
										: 'nav-link inactive'
								}>
								<Link className="nav-link" to="/locate-us">
									Locate Us
								</Link>
							</li>
							<li
								className={({ isActive }) =>
									isActive
										? 'nav-link active'
										: 'nav-link inactive'
								}>
								<Link className="nav-link" to="/about">
									About
								</Link>
							</li>
							<li
								className={({ isActive }) =>
									isActive
										? 'nav-link active '
										: 'nav-link inactive'
								}>
								<Link
									className="nav-link disabled"
									to="/contact">
									Contact Us
								</Link>
							</li>
						</ul>
						{props.searchBar ? (
							<form className="d-flex" role="search">
								<input
									className="form-control me-2"
									type="search"
									placeholder="Search"
									aria-label="Search"
								/>
								<button
									className="btn btn-outline-success"
									type="submit">
									Search
								</button>
							</form>
						) : (
							''
						)}
						{props.dark ? <button
							className="btn btn-success theme"
							onClick={themeChange}
							id="themes-mode">
							Dark Mode
						</button> : ""}
					</div>
				</div>
			</nav>
		</React.Fragment>
	);
};

Header.defaultProps = {
	title: 'Music Academy',
};

Header.propTypes = {
	title: PropTypes.string,
	searchBar: PropTypes.bool,
};

export default Header;
