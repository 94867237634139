import React from "react";
import './slider.css';
import img1 from "../img/1.jpg";
import img2 from "../img/2.jpg";
import img3 from "../img/3.jpg";

const Slider = (props) => {
  return (
    <React.Fragment>
      <div className="Slider"  data-bs-ride="carousel">
        <div id="carouselExampleIndicators" class="carousel slide"  data-bs-ride="carousel">
          <div class="carousel-indicators">
            <button
              type="button"
              data-bs-target="#carouselExampleIndicators"
              data-bs-slide-to="0"
              class="active"
              aria-current="true"
              aria-label="Slide 1"
            ></button>
            <button
              type="button"
              data-bs-target="#carouselExampleIndicators"
              data-bs-slide-to="1"
              aria-label="Slide 2"
            ></button>
            <button
              type="button"
              data-bs-target="#carouselExampleIndicators"
              data-bs-slide-to="2"
              aria-label="Slide 3"
            ></button>
          </div>
          <div id="carouselExampleFade" className="carousel slide carousel-fade">

          <div class="carousel-inner">
            <div class="carousel-item active" data-bs-interval="5000">
              <img src={img1} class="d-block w-100" alt="1" />
            </div>
            <div class="carousel-item"  data-bs-interval="5000">
              <img src={img2} class="d-block w-100" alt="2" />
            </div>
            <div class="carousel-item" data-bs-interval="5000">
              <img src={img3} class="d-block w-100" alt="3" />
            </div>
          </div>
          <button
            class="carousel-control-prev"
            type="button"
            data-bs-target="#carouselExampleIndicators"
            data-bs-slide="prev"
          >
            <span class="carousel-control-prev-icon" aria-hidden="true"></span>
            <span class="visually-hidden">Previous</span>
          </button>
          <button
            class="carousel-control-next"
            type="button"
            data-bs-target="#carouselExampleIndicators"
            data-bs-slide="next"
          >
            <span class="carousel-control-next-icon" aria-hidden="true"></span>
            <span class="visually-hidden">Next</span>
          </button>
            </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Slider;
