import React from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';

const Contact = (props) => {
	return (
		<React.Fragment>
			<Helmet>
				<title> Music Academy | Contact Us</title>
			</Helmet>

			<div className="Contact mt-5">
				<div className="container">
					<form>
						<div className="mb-3">
							<label for="exampleInputEmail1" className="form-label">
								Your Name
							</label>
							<input
								type="text"
								className="form-control"
								id="exampleInputText1"
								aria-describedby="emailHelp"
							/>
						</div>
						<div className="mb-3">
							<label for="exampleInputEmail1" className="form-label">
								Email address
							</label>
							<input
								type="email"
								className="form-control"
								id="exampleInputEmail1"
								aria-describedby="emailHelp"
							/>
							<div id="emailHelp" className="form-text">
								We'll never share your email with anyone else.
							</div>
						</div>
						<div className="mb-3">
							<label
								for="exampleInputPassword1"
								className="form-label">
								Password
							</label>
							<input
								type="password"
								className="form-control"
								id="exampleInputPassword1"
							/>
						</div>
						<div className="mb-3">
							<label
								for="exampleFormControlTextarea1"
								className="form-label">
								Your Message
							</label>
							<textarea
								className="form-control"
								id="exampleFormControlTextarea1"
								rows="3"></textarea>
						</div>
						<button type="submit" className="btn btn-primary">
							Submit
						</button>
					</form>
				</div>
			</div>
		</React.Fragment>
	);
};
export default Contact;
